@import 'src/styles/classes';
.code {
  padding: 0px !important;
  font-size: 60px !important;
  height: 80px !important;
  width: 60px !important;
  text-align: center !important;

  box-shadow: none;
  margin-right: 0.5rem !important;
  margin-top: 0.5rem !important;
  outline: none !important;
  &:focus {
    outline: none !important;
    border-color: $primary;
    background-color: rgba($primary, 0.2);
    box-shadow: 0 0 0px transparent !important;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover {
    -webkit-box-shadow: 0 0 0px 1000px gray inset;
    -webkit-text-fill-color: #ffffff;
    // -webkit-box-shadow: 0 0 0px 1000px $background-lighter-100 inset;
  }
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px gray inset;
    // -webkit-box-shadow: 0 0 0px 1000px $background inset;
  }
  &::placeholder {
    color: rgba(gray, 0.4);
    // color: rgba($foreground, 0.4);
  }
}
