.yellow {
  background: #ffd500;
}

.united {
  height: 120px;
}

.zelenskyy {
  margin-bottom: -120px;
  height: 240px;
  margin-left: auto;
  margin-right: auto;
}

.footer {
  height: calc(100vh - 727px);
}
