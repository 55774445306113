@import 'src/styles/all';

.card-donation {
  background: #ebebeb;
  height: 100%;
  text-align: center;
}

.donation-amount {
  padding: 1rem;
  cursor: pointer;
  &.selected {
    background: black;
    color: white;
  }
}

span.custom-amount-span {
  width: 2rem !important;
}

input.custom-amount-input {
  padding-left: 2rem !important;
}

.donate-button {
  color: $text !important;
  font-weight: 600;
}

.card-grid {
  display: flex;
  flex-wrap: wrap;

  .card-cell {
    margin-bottom: 48px;
    margin-right: 24px;
    position: relative;
    cursor: pointer;
  }
}

.options {
  position: absolute;
  bottom: -32px;
}

.amounts {
  margin-top: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: -0.5rem;

  .amount {
    background: $background-tint-4;
    background: #ffffff;
    border: 1px solid $border;
    height: 60px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0.5rem;

    &.active {
      border-color: $primary;
      background-color: rgba($primary, 0.2);
    }

    .portrait {
      flex: 1;
    }
  }

  .field-amount {
    margin: 0.5rem;
    grid-column: 2/4;

    .custom-amount {
      height: 60px;
      padding-left: 2rem !important;
    }

    .icon-dollar {
      height: 60px !important;
      width: 2rem !important;
    }
  }
}

.nfts {
  margin: -0.5rem;

  .nft {
    border: 1px solid $border;
    margin: 0.5rem;
    background: #ffffff;
    display: flex;
    text-align: left;

    img {
      width: 120px;
      height: 120px;

      &.coinbase {
        object-fit: contain;
      }

      &.cryptos {
        width: 400px;
        margin: auto;
        height: auto;
      }
    }
  }
}

.adjust-mobile {
  font-size: 2rem;
  @include touch {
    font-size: 16px !important;
  }
}

._8mIf0 {
  flex-wrap: wrap;
}
