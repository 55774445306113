@import 'src/styles/all';

.malibu {
  background: #ccd2e6;
  min-height: calc(100vh - 156px);
}

.header {
  padding: 4rem 0;
  background: url('https://images.unsplash.com/photo-1657870329074-e5c29e668d2d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1932&q=80');

  .diego {
    color: #584d68;
    font-size: 60px;

    .k {
      font-size: 40%;
    }
  }

  .avatar {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin: auto;
  }

  .description {
    max-width: 720px;
    margin: auto;
  }
}

.collections {
  justify-content: center;
  flex-wrap: wrap;

  .card {
    flex-shrink: 0;
    margin: 0 0.5rem 1rem;
  }
}

.collection-skeleton {
  margin: 0.5rem;
}

.only {
  &-desktop {
    width: 100%;
    display: flex;

    @include touch {
      display: none;
    }
  }

  &-mobile {
    width: 100%;
    display: none;

    @include touch {
      display: flex;
    }
  }
}
