@import 'src/styles/all';

.ocean {
  max-width: 960px !important;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.terms-list {
  content: counters(item, '.') ' ';
  counter-reset: item;
  display: table;
  margin-left: 0;
}

.primary-list {
  margin-left: -1.5rem;
  counter-increment: item;
  display: table-row;
  &::before {
    content: counter(item) '. ';
    display: table-cell;
    text-align: right;
    font-weight: 700;
    font-size: 1.5rem;
    color: #304555;
    padding-right: 0.3rem;
  }

  .primary-sub-list {
    // content: counters(item, '.'subitem, '.') ' ';
    counter-reset: subitem;
    margin-left: 0;

    .sub-list-item {
      counter-increment: subitem;
      display: table-row;

      &::before {
        content: counters(item, '') '.' counters(subitem, '') '. ';
        display: table-cell;
        text-align: right;
        font-weight: 700;
        font-size: 1rem;
        color: #304555;
        padding-right: 0.3rem;
      }
    }
  }
}

.unordered-list {
  display: table;
  margin-left: 1rem;
  li {
    list-style: disc;
    &::marker {
      padding-right: 1.5rem;
    }
  }
}

.ordered-list {
  display: table-row;

  li {
    counter-increment: ordered-list-item;
    display: table-row;

    &::before {
      content: counters(ordered-list-item, '') '. ';
      display: table-cell;
      text-align: right;
      font-weight: 700;
      color: #304555;
      padding-right: 1.3rem;
    }
  }
}

.item-list {
  display: list-item;
  list-style-type: disc;

  &::before {
    display: table-cell;
    padding-right: 0.3rem;
  }
}
