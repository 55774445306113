@import 'src/styles/all';

.header {
  position: relative;

  .header-img {
    width: 100%;
    height: 600px;
    object-position: bottom center;
    object-fit: cover;
  }

  .header-text {
    color: #08305a;
    position: absolute;
    top: 40px;
    left: 60%;
    line-height: 1;

    .a {
      font-size: 36px;
    }

    .b {
      font-size: 48px;
      font-weight: 700;
    }

    @include touch {
      width: 100%;
      left: 0;
      text-align: center;
    }
  }

  .flag {
    text-align: center;
    position: absolute;
    bottom: -72px;
    width: 100%;
    display: flex;
    justify-content: center;

    .flag-inner {
      iframe {
        width: 560px;

        @include touch {
          width: calc(100vw - 4rem);
        }
      }
    }

    img {
      margin: auto;
      width: 160px;
      height: 160px;
      border-radius: 50%;
      background-size: cover;
    }
  }
}

.main {
  padding-top: 25px;

  .donate {
    max-width: 960px;
    text-align: center;
    margin: auto;

    .dono {
      width: 200px;
      height: 200px;
      margin: auto;
    }

    .amounts {
      margin-top: 16px;
      display: grid;
      grid-template-columns: repeat(5, 20%);

      .amount {
        background: $background-tint-4;
        height: 80px;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0.5rem;
        cursor: pointer;

        &.active {
          background: linear-gradient(to bottom, #2159af, #f0cf46);
          color: #ffffff;
        }

        .custom-amount {
          height: 80px;
        }

        .portrait {
          height: 80px;
        }

        .flex {
          flex: 1;
        }
      }
    }
  }
}

.circle {
  color: #08305a;
  font-size: 36px;
  font-weight: 700;

  &.small {
    font-size: 24px;
  }
}

.bru {
  box-shadow: 0 0 8px #808080;
  border-radius: 8px;
  padding: 120px 1rem 1rem;
  margin-top: 120px;
  position: relative;

  .artist {
    background: #667e37;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    right: 0;
    top: -100px;
    margin: auto;
  }
}

.mission {
  color: #ffffff;
  position: relative;
  background: url('/assets/images/mission.png');
  background-size: cover;

  .building {
    height: 100%;
    width: 100%;
  }

  .background {
    position: absolute;
    height: 100%;
  }
}

.partners {
  display: flex;
  justify-content: center;

  @include touch {
    width: 100%;

    .partner {
      justify-content: center;
    }
  }

  .partner {
    justify-content: center;

    img {
      height: 160px;
    }
  }
}

.raised {
  font-weight: 700;
  background: url('/assets/images/raised-background.png');
  background-size: cover;
  padding: 2rem 0;
  text-align: center;

  .title {
    color: #ffd500;
    font-size: 36px;

    @include touch {
      font-size: 28px;
    }
  }

  .amount {
    color: #ffffff;
    font-size: 120px;

    @include touch {
      font-size: 48px;
    }
  }
}

.about {
  padding: 2rem 0;
}

.organize {
  .text {
    margin-top: 1rem;
  }

  @include touch {
    flex-direction: column;

    img {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    .fix-long {
      white-space: unset;
      padding: 2rem;
    }
  }
}

.reverse {
  @include touch {
    display: flex;
    flex-direction: column-reverse;
  }
}

.donate-footer {
  background: #ececec;
  padding: 0.5rem 1rem;
  font-size: 12px;
}

.donate-nft-image {
  width: 240px;
  margin: 1rem auto;
}

.united {
  background-color: #ffd500;
  height: 200px;
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 48px;

  .zelenskyy {
    position: absolute;
    height: 320px;
    bottom: 16px;
    left: 48px;
  }

  @include touch {
    height: 400px;
    padding-top: 80px;
    margin-top: 160px;

    .zelenskyy {
      left: 0;
      right: 0;
      margin: auto;
      top: -400px;
      height: 200px;
    }
  }

  .z-1 {
    text-align: center;
  }
}

.charity-logo {
  height: 40px;
}

video {
  max-height: 420px !important;
}

._LGaYj {
  @include touch {
    margin-left: 0 !important;
    margin-right: 0 !important;

    img {
      width: 100%;
      height: unset;
    }
  }
}
