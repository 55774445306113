@import 'src/styles/classes';

.navigation {
  color: #ffffff;
  height: 80px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  background-color: #2b2c30;
  display: flex;
  align-items: center;

  .title {
    cursor: pointer;

    .logo {
      height: 2rem;
      margin-top: 0.5rem;
    }

    .type {
      white-space: nowrap;
      margin-left: 0.5rem;
    }
  }

  .mobile-mirage {
    padding: 0 1rem;

    ul {
      margin-top: 1rem;

      li {
        padding: 0.5rem 0;
        cursor: pointer;
      }
    }
  }
}

.footer {
  background: #ffd500;
  padding: 24px;
  font-size: 18px;
  display: flex;
  width: 100%;
}

.routes {
  min-height: calc(100vh - 156px);
  margin-top: 80px;
  line-height: 1.5;
}

.map {
  display: flex;
  align-items: center;

  .mobile-menu {
    padding: 0;
    width: 36px;
    height: 32px;

    @include desktop {
      display: none;
    }
  }

  .map-list {
    margin-left: auto;
  }

  .clearance {
    font-size: 14px;
    background-color: #ffffff;
    border-color: #ffffff;
    color: #000000;

    &.is-outlined {
      border-color: #ffffff;
      background: none;
      color: #ffffff;
    }
  }

  .map-menu {
    li:last-child {
      padding-right: 1rem;
    }

    @include touch {
      display: none;

      li {
        margin-right: 0.5rem !important;

        &:last-child {
          margin-right: 0 !important;
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .avatar {
    width: 40px;
    height: 40px;
  }

  .account {
    flex-shrink: 0;

    @include touch {
      margin-left: auto;
    }
  }
}

.copyright {
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.create-account {
  @include touch {
    display: none;
  }
}

.subscribe {
  background: none;
  border: none;
  border-bottom: 1px solid black;
  height: 40px;
  outline: none;
  font-size: 20px;
  width: 50%;
}

.tiktok {
  height: 20px;
  margin-right: 2px;
}
