@import 'styles/all';

.size {
  &-sm {
    max-width: 480px !important;
  }

  &-auto {
    width: auto !important;
    height: auto !important;
  }

  &-md {
    width: 950px;
    max-width: 100% !important;
  }
  &-full {
    overflow: hidden;
    padding: 0 !important;
    width: 100vw !important;
    height: 100vh !important;
    max-height: 100vh !important;
  }
}

.lightbox {
  background-color: rgba(#000000, 0.8) !important;
}

.is-borderless {
  padding: 0 !important;
}

.modal-lightbox {
  padding: 0.5rem;
  background-color: #ffffff;
  max-width: 80vw;
  max-height: 80vh;
  z-index: 1000;
}

.modal-black {
  background: #000 !important;
}

.close {
  position: absolute;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  right: 1rem;
  top: 1rem;
  background: rgba($primary, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: rgba($primary, 0.4);
  }

  span {
    font-size: 18px;
  }
}
