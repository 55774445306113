@import url('https://fonts.googleapis.com/css2?family=Cormorant:wght@400;600;700&family=Material+Icons+Outlined&display=swap');
@import 'src/styles/classes';
@import '~bulma/bulma';

$yellow: #fed500;

html {
  font-size: 16px;
  overflow: auto;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1b262f;
  font-size: 14px;
  line-height: 1.2;
}

a,
.is-link {
  color: $anchor;
  cursor: pointer;

  &:hover {
    color: $anchor;
  }

  &.is-link-neutral {
    color: $primary;
  }
}

p {
  margin-block: 1rem;
}

div {
  box-sizing: border-box;
}

ol {
  margin-left: 1.5rem;
}

img {
  display: block;
  object-fit: cover;
}

video {
  background: #ffffff;
  border: 1px solid $border;
  padding: 1rem;
  display: block;
}

.serif {
  font-family: 'cormorant', serif;
  line-height: 1;
}

.carousel-root {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;

  .carousel {
    height: 100%;

    .slider-wrapper {
      height: 100%;

      .slider {
        height: 100%;
      }
    }
  }
}

.button {
  background: $primary;
  color: #ffffff;
  border: 1px solid $primary;
  border-radius: 0 !important;
  padding: 0 1.5rem;

  &:hover {
    border-color: $primary;
    background: darken($primary, 10%);
    color: white;
  }

  &:focus {
    color: #ffffff;
    box-shadow: none !important;
  }

  &:active {
    color: #ffffff;
  }

  &:disabled {
    border: 1px solid $primary;
    background: $primary;
    opacity: 0.6;
    cursor: default;
  }

  &.is-clear {
    background: none;
    color: $text;
    border: none;
  }

  &.is-outlined {
    background: none;
    color: $primary;
  }

  &.is-small {
    padding: 0.125rem 0.5rem;
    font-size: 12px;
    height: unset;
  }

  &.is-yellow {
    background: #f8d548;
    border: 1px solid #f8d548;
    color: #000000;
  }
}

.modal {
  z-index: 10000;

  &.image-viewer {
    .modal-content {
      width: 100vw !important;
      height: 100vh !important;
      padding: 0 !important;
    }
  }
  .modal-content {
    background: #fff;
    padding: 2rem;
  }
}

.list {
  list-style-type: none;
  margin: 0;

  &.inline {
    display: flex;
    align-items: center;

    li:not(:last-child) {
      margin-right: 1rem;
    }
  }

  &.link {
    li {
      cursor: pointer;
    }
  }
}

.classic-list {
  list-style-type: disc;
  margin-left: 1rem;

  &.check {
    list-style-type: '✓';
    li {
      padding-left: 8px;
    }
  }
}

.container {
  @include touch {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.has-text-muted {
  color: $primary-muted;
}

.field {
  .label {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0.25rem;
  }

  .control {
    .input {
      border-color: $border;
      border-radius: 0;
      box-shadow: none;

      &.is-danger {
        border-color: $red;
      }

      &:focus {
        border-color: $primary;
        background-color: rgba($primary, 0.2);
      }
    }
  }
}

.button-nifty {
  border-radius: 30px !important;
  border: none;

  &.is-orange {
    background: linear-gradient(to right, #cc4a27, #de643b);
    font-size: 18px !important;
  }

  &.is-blue {
    background: linear-gradient(to right, #1e79a9, #2592ca);
  }
}

.table {
  background: #f8faff;
}

.lds-spinner {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: ' ';
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #000000;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.default-list {
  list-style: disc;
  margin-left: 1rem;
}

.MuiSwitch-root {
  .MuiSwitch-thumb {
    color: #000000;
  }

  .MuiSwitch-track {
    background: #000000;
  }

  .Mui-checked {
    + .MuiSwitch-track {
      background: #000000 !important;
    }
  }
}
