.card-grid {
  display: flex;
  flex-wrap: wrap;

  .card-cell {
    margin-bottom: 1rem;
    margin-right: 1rem;
    position: relative;
    cursor: pointer;
  }
}

.purchase-spinner {
  text-align: center;

  img {
    margin: auto;
    height: 120px;
  }
}

.mb-2rem {
  margin-bottom: 2rem;
}

.ethereum {
  height: 20px;
}
