@import 'src/styles/all';

.ocean {
  max-width: 960px !important;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.terms-list {
  content: counters(item, '.') ' ';
  counter-reset: item;
  display: table;
  margin-left: 0;
}

.primary-list {
  margin-left: -1.5rem;
  counter-increment: item;
  display: table-row;

  &::before {
    content: counter(item) '. ';
    display: table-cell;
    text-align: right;
    font-weight: 700;
    font-size: 1.5rem;
    color: #304555;
    padding-right: 0.3rem;
  }
}

.unlisted-list {
  display: table;
}

.item-list {
  display: list-item;
  list-style-type: disc;

  &::before {
    display: table-cell;
    padding-right: 0.3rem;
  }
}
