@import 'src/styles/all';

.seller-profile {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 12px;

  &-img {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 1.5rem;
    object-fit: cover;
    margin-right: 0.5rem;
  }
}

.bottom {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;

  @include touch {
    flex-wrap: wrap;

    .mobi {
      font-size: 1.25rem !important;
    }
  }
}
