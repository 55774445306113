@import 'src/styles/all';

.collection-card {
  box-shadow: inset 0 0 0 1px $border;
  cursor: pointer;
  transition: 500ms ease;
  padding: 1rem;
  position: relative;
  background: #ffffff;
  height: 100%;

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    transition: 500ms ease;
  }

  .preview {
    width: 100%;

    .main {
      aspect-ratio: 1/1;
    }
  }

  .details {
    margin-top: 0.5rem;

    .name {
      font-size: 20px;
      font-weight: 700;
    }

    .avatar {
      border-radius: 50%;
      height: 32px;
      width: 32px;
    }

    .description {
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      line-clamp: 3;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  .main {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
