@import 'src/styles/all';

.header {
  position: relative;
  .flags {
    height: 16px;
    background-image: url('/assets/images/flags.jpg');
    background-size: auto 100%;
    background-repeat: repeat;
  }

  .goal {
    text-align: center;
    position: absolute;
    bottom: -52px;
    width: 100%;

    .amount {
      margin: auto;
      width: 120px;
      height: 120px;
      border-radius: 50%;
      background-image: url('/assets/images/ukraine-flag.svg');
      background-size: cover;
      box-shadow: inset 0 0 0 8px #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-size: 40px;
      font-weight: 700;
    }
  }
}

.main {
  padding-top: 60px;

  .donate {
    max-width: 640px;
    margin: auto;

    .dono {
      background: $background-tint-4;
      display: flex;
      align-items: center;
      padding: 0.5rem;

      img {
        width: 80px;
        height: 80px;
      }

      .amount {
        flex: 1;
        text-align: center;
      }
    }
  }
}

.custom-amount {
  height: 96px !important;
}
