@import 'src/styles/all';

.malibu {
  background: #ccd2e6;
  min-height: calc(100vh - 156px);
}

.piece {
  max-width: 1040px !important;
  padding-top: 2rem;
}

.summary {
  width: 480px;

  @include touch {
    width: 100%;
  }
}

.caustic {
  width: 100%;

  .pricing {
    //display: flex;
    margin: 0 1rem;

    .flex {
      width: 100%;
    }

    @include touch {
      justify-content: center;
    }
  }

  .nox {
    width: 12rem;
    background: #946c9e;
    border: none;
    padding: 1.5rem;
    font-weight: 700;

    &:hover {
      background: darken(#946c9e, 10%);
    }
    &.wallet {
      margin-left: 1rem;
    }

    @include touch {
      width: 80%;
      margin-bottom: 0.5rem;

      &.wallet {
        margin-left: 0;
      }
    }
  }
}

.section {
  padding: 1rem 0;

  &:first-child {
    padding-top: 0;
  }

  .header {
    color: $primary-muted;
    font-weight: 600;
    font-size: 16px;
  }

  .content {
    margin: 0.5rem 1rem;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $primary-muted;
  }
}

.piece-detail-wrapper {
  padding: 0 4rem;

  .piece-detail {
    width: 100%;
    padding: 0 5rem;

    @include touch {
      margin: auto;
    }
  }
}

.placeholder {
  height: 120px;
}

.small-image {
  width: 100%;
  display: block;
  visibility: hidden;
}

.show {
  visibility: visible;
}

.large-image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.player {
  width: 100%;
  aspect-ratio: 16/9;
}

.signature {
  height: 40px;
}

.collaborator-avatar {
  width: 40px;
  height: 40px;
}

.pricing-padding {
  padding-top: 40px;

  @include touch {
    padding-top: 40px;
  }
}

.full-spinner {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;

  img {
    height: 280px;
  }
}

.collection-card {
  border: 1px solid #c8cdd4;
  background: #ffffff;
  padding: 1rem;
  cursor: pointer;

  .thumbnail {
    margin: 0 auto;
    width: 100%;
    object-fit: contain;
    max-height: 480px;

    &.thumbnail-video {
      position: relative;

      video {
        width: 100%;
        height: 100%;
        display: block;
        max-height: 480px;
      }

      .play-video {
        background: rgba(#000000, 0.2);
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 64px;
          filter: invert(100%);
        }
      }
    }

    @include touch {
      max-height: 50vh;
    }
  }

  .thumbnail-audio {
    .preview-audio {
      width: 280px;
      height: 280px;
      margin: auto;
    }
    .play-audio {
      display: block;
      width: 100%;
      height: 24px;
      margin-top: 0.5rem;
    }
  }
}

.item-info {
  margin-bottom: 2rem;
  .header {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 1rem;
  }
}
